import { CENTS_IN_DOLLAR, MILLISECONDS_IN_SECOND } from '@app/constants';
import { ModelMixin } from '@app/models/core/base-generic.model';
import { SubscriptionItem } from '@models/settings/billing/subscription-item.model';
import { parse } from 'date-fns';

export type Discount = {
    coupon: Coupon;
};

export type Coupon = {
    percentOff: number;
    amountOff: number;
};

/**
 * Subscription represents a Stripe Subscription
 * Beware, this is not backed by a database model.
 * This will contact stripe and be slow.
 * Do not use unless you know what you are doing.
 */
export class Subscription extends ModelMixin<Subscription>() {
    protected static _resource = 'billingSubscriptions';

    protected static _version = 'v2';

    get status(): 'incomplete' | 'active' | 'trialing' {
        return this._attributes['status'];
    }

    get trialEnd(): Date {
        return parse(Math.floor(+this._attributes['trialEnd']) * MILLISECONDS_IN_SECOND);
    }

    get amount(): number {
        return +this._attributes['amount'] / CENTS_IN_DOLLAR;
    }

    get latestInvoice(): string | null {
        return this._attributes['latestInvoice'];
    }

    get discount(): Discount | null {
        return this._attributes['discount'];
    }

    set subscriptionItems(val: SubscriptionItem[]) {
        this.setMany('subscriptionItems', val);
    }

    get subscriptionItems(): SubscriptionItem[] {
        return this.hasMany(SubscriptionItem, 'subscriptionItems');
    }
}
