import { CENTS_IN_DOLLAR } from '@app/constants';
import { ModelMixin } from '@app/models/core/base-generic.model';
import { ProductPlan } from './product-plan.model';

export class SubscriptionItem extends ModelMixin<SubscriptionItem>() {
    protected static _resource = 'billingSubscriptions/:billingSubscription/billingSubscriptionItems';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['quantity', 'plan'];

    get quantity(): number {
        return this._attributes['quantity'];
    }

    get plan(): ProductPlan {
        return this._attributes['plan'];
    }

    set plan(val: ProductPlan) {
        this._attributes['plan'] = val;
    }

    get planIdentifier(): string {
        return this._attributes['plan']['id'];
    }

    get product(): string {
        return this._attributes['plan']['product'];
    }

    get active(): boolean {
        return this._attributes['plan']['active'];
    }

    get amount(): number {
        return +this._attributes['plan']['amount'] / CENTS_IN_DOLLAR;
    }

    get interval(): string {
        return this._attributes['plan']['interval'];
    }
}
