import { CENTS_IN_DOLLAR, MILLISECONDS_IN_SECOND } from '@app/constants';
import { ModelMixin } from '@app/models/core/base-generic.model';
import { parse } from 'date-fns';

export interface InvoiceLineItem {
    amount: number;
    description: string;
    quantity: number;
    plan: string | null;
    period: { start: number; end: number };
}

export class Invoice extends ModelMixin<Invoice>() {
    protected static _resource = 'billingInvoices';

    protected static _version = 'v2';

    get invoicePdf(): boolean {
        return this._attributes['invoicePdf'];
    }

    get hostedInvoiceUrl(): boolean {
        return this._attributes['hostedInvoiceUrl'];
    }

    get paid(): boolean {
        return this._attributes['paid'];
    }

    get status(): string {
        return this._attributes['status'];
    }

    get amountDue(): number {
        return +this._attributes['amountDue'] / CENTS_IN_DOLLAR;
    }

    get amountPaid(): number {
        return +this._attributes['amountPaid'] / CENTS_IN_DOLLAR;
    }

    get periodStart(): Date | null {
        if (!this.lines || !this.lines.length || !this.lines[0].period) {
            return null;
        }

        return parse(Math.floor(+this._attributes['periodStart']) * MILLISECONDS_IN_SECOND);
    }

    get periodEnd(): Date | null {
        if (!this.lines || !this.lines.length || !this.lines[0].period) {
            return null;
        }

        return parse(Math.floor(+this._attributes['periodEnd']) * MILLISECONDS_IN_SECOND);
    }

    get dueDate(): Date | null {
        return this._attributes['dueDate']
            ? parse(Math.floor(+this._attributes['dueDate']) * MILLISECONDS_IN_SECOND)
            : null;
    }

    get lines(): InvoiceLineItem[] {
        return this._attributes['lines']['data'];
    }
}
