import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { XeroIntegrationService } from '@app/modules/settings/services';
import { NotifyService } from '@app/services';

@Component({
    templateUrl: './xero-callback.template.html',
})
export class XeroCallbackView implements OnInit {
    constructor(
        private xeroService: XeroIntegrationService,
        private router: Router,
        private notify: NotifyService
    ) {}

    async ngOnInit(): Promise<void> {
        const searchParams = new URLSearchParams(window.location.search);
        if (this.invalidCallback(searchParams)) {
            return this.redirectToIntegrationsPage();
        }

        this.xeroService.performKeyExchange(searchParams).then((isSuccess) => this.redirectAfterKeyExchange(isSuccess));
    }

    private redirectAfterKeyExchange(successful: boolean): void {
        if (successful) {
            // We don't have a way of knowing when the job to pull
            // down the available accounts has finished so wait for
            // a few seconds before redirecting to the manage page.
            setTimeout(() => {
                this.notify.success('payroll.xeroCallback.connectionWithXeroSuccessful');
                this.router.navigate(['/settings/integrations-v2/xero/manage']);
            }, 2500);
        } else {
            this.notify.error('payroll.xeroCallback.couldNotCreateXeroConnectionPleaseTryAgain');
            this.redirectToIntegrationsPage();
        }
    }

    private invalidCallback(searchParams: URLSearchParams): boolean {
        if (searchParams.has('code') && searchParams.has('state')) {
            return false;
        }

        return true;
    }

    private redirectToIntegrationsPage(): void {
        this.router.navigate(['/settings/integrations-v2']);
    }
}
